@import '../../../../styles/variables.module';

.container {
  max-width: 100%;

  .filters {
    border-radius: 8px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    padding: 16px;
    margin-bottom: 4px;
    flex-wrap: wrap;

    .filterItem .sortItem {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .btcTx {
    overflow: hidden;
  }

  .split {
    width: 100%;
    background-color: #f5f5f5;
    height: 4px;
    margin-left: 10px;
  }

  table {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #fff;
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
    word-break: break-word;
    text-overflow: ellipsis;

    th,
    td {
      height: 4rem;
      padding: 0 8px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      max-width: 100%;
      line-height: 19px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        padding-left: 2em;
      }

      &:last-child {
        text-align: right;
        padding-right: 2em;
      }

      &.noRecords:last-child {
        text-align: center;
      }
    }

    thead {
      th {
        font-size: 16px;
        font-weight: 400;

        svg {
          line-height: 4rem;
          vertical-align: middle;
        }

        &:last-child {
          text-align: right;
        }
      }

      padding: 10px;
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid #f5f5f5;
        }

        .type {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  a {
    color: var(--primary-color);
  }

  .height {
    font-family: Roboto, inherit, sans-serif;
  }

  .cells {
    white-space: pre;
  }

  @media (width <= $mobileBreakPoint) {
    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          border-top: 4px solid #ededed;
        }

        td {
          position: relative;
          display: flex;
          flex-direction: column;
          height: auto;
          padding: 0.625rem 1rem;

          &:first-child {
            padding-left: 1rem;
            max-width: calc(100vw - 2.25rem);
          }

          &:last-child {
            text-align: left;
          }

          &:not(:last-child)::after {
            position: absolute;
            bottom: 0;
            left: 1rem;
            right: 1rem;
            content: '';
            display: block;
            height: 1px;
            background: #f5f5f5;
          }

          &::before {
            content: attr(title);
            font-size: 1rem;
            color: #666;
          }
        }
      }
    }
  }
}
