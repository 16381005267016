@import url('../assets/fonts/fonts.css');

body {
  --primary-color: #00cc9b;
  --primary-dimmed-color: #99e6ca;
  --secondary-color: #d7fdf2;
  --accent-color: #fa504f;
  --primary-text-color: #333;
  --primary-hover-bg-color: #e8fff1;
  --primary-chiffon-color: #e6fcf7;
  --navbar-height: 64px;
  --table-separator-color: #f5f5f5;
  --decimal-color: #999;
  --input-bg-color: #f5f5f5;
  --btc-primary-color: #fb992c;
  --btc-primary-dimmed-color: #ffdba6;
  --btc-secondary-color: #fff5e2;
  --antd-wave-shadow-color: var(--primary-color);
  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;

  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

body[data-chain-type='testnet'] {
  --primary-color: #9a2cec;
  --primary-dimmed-color: #e8b0fc;
  --secondary-color: #f1d7fd;
  --primary-hover-bg-color: #eeeafc;
  --primary-chiffon-color: #eeeafc;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, Lato, sans-serif, Montserrat, 'PingFang SC', -apple-system;
}

.monospace {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*[role]:focus {
  outline: none;
}

.container {
  width: auto;
  margin: 0 120px;
}

@media (width <= 1440px) {
  .container {
    width: auto;
    margin: 0 100px;
  }
}

@media (width <= 1200px) {
  .container {
    width: auto;
    margin: 0 45px;
  }
}

@media (width <= 750px) {
  .container {
    width: auto;
    margin: 0 18px;
  }
}

a {
  text-decoration: none;
  font-size: inherit;
  font-weight: 500;
  color: inherit;
}

/* stylelint-disable selector-class-pattern */
@media (width <= 750px) {
  .rc-pagination-options-quick-jumper {
    display: none;
  }
}

@media (width <= 750px) {
  .ant-popover-inner-content {
    padding: 5px;
  }
}

.ant-tooltip-inner {
  white-space: pre-wrap;
  border-radius: 8px;
  padding: 10px 16px;
}

.ant-input:focus {
  border-color: none;
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-color: #e5e5e5 !important;
  box-shadow: none;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: var(--primary-color);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: var(--primary-color);
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color);
}

.ant-radio-inner::after {
  background-color: var(--primary-color);
}

.ant-radio-checked::after {
  border-color: var(--primary-color);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--primary-color);
}

a:hover,
.ant-btn-primary:hover,
.ant-tabs-tab:hover {
  color: var(--primary-color);
}

.ant-input-number-affix-wrapper {
  border-radius: 4px;
  box-shadow: none;
}

.ant-input-number-affix-wrapper.ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:hover {
  border-color: var(--primary-color) !important;
}
