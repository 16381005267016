.switch {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 9999px;
  border-width: 2px;
  border-color: transparent;
  width: 2.75rem;
  height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  cursor: pointer;

  &:focus-visible {
    outline-style: none;
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    --ring-offset-width: 2px;

    box-shadow: 0 0 0 var(--ring-offset-width) var(--ring-offset-color), var(--ring-shadow);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &[data-state='checked'] {
    background-color: var(--primary-color);
  }

  &[data-state='unchecked'] {
    background-color: #aaa;
  }
}

.thumb {
  background-color: #fff;
  display: block;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  pointer-events: none;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);

  &[data-state='checked'] {
    transform: translateX(1.25rem);
  }

  &[data-state='unchecked'] {
    transform: translateX(0);
  }
}
