@import '../../../styles/variables.module';

.contentWrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 80vw;
  max-width: 280px;
  padding: 24px;

  @media screen and (width <= 750px) {
    padding: 16px;
    width: calc(100vw - 32px);
  }

  p {
    margin: 0;
  }
}

.modalTitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.modalContent {
  width: 100%;
  margin-top: 32px;
}

.languageSelect {
  color: #333;
}

.closeBtn {
  border: none;
  background: transparent;
  cursor: pointer;

  img {
    width: 13px;
    height: 13px;
  }
}

.loading {
  text-align: center;
}

.switcher {
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  label {
    color: black;
  }
}

.alert {
  position: sticky;
  color: #fff;
  font-size: 14px;
  font-weight: 450;
  line-height: 1.2;
  text-align: center;
  padding: 1rem;
  background-color: #fa8f00;

  @media (width <= $mobileBreakPoint) {
    text-align: left;
  }
}

.clickable {
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
}

.nodeEditForm {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 16px;
  width: 100%;

  label {
    display: block;
    font-weight: bold;
  }

  input {
    display: block;
    width: 100%;
    line-height: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    background: white;
  }
}

.btn {
  background-color: var(--primary-color);
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  padding: 8px 24px;
  color: white;
  border: none;
  border-radius: 4px;
}

.nodeList {
  width: 100%;
  border-top: 1px solid rgb(0 0 0 / 20%);
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  max-height: 320px;
}

.node {
  padding: 8px;
  border-radius: 4px;
  transition: all 0.2s;
  background-color: #fafafa;

  input[type='checkbox'] {
    cursor: pointer;
  }

  .nodeTitle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .nodeAlias {
    font-size: 16px;
    font-weight: bold;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .nodeSubtitle {
    font-size: 12px;
    color: rgb(136 136 136);
  }
}

.nodeAction {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  color: black;

  &:hover {
    color: var(--primary-color);
  }
}
