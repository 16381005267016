@import '../../styles/variables.module';

.contentWrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  // content width
  width: 80vw;
  padding: 23px 40px;

  @media screen and (width <= 750px) {
    padding: 16px;
    width: calc(100vw - 32px);
  }
}

.modalTitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-weight: 500;
  font-size: 16px;
}

.tooltipIcon {
  width: 16px;
  height: 16px;
}

.closeBtn {
  border: none;
  background: transparent;
  cursor: pointer;

  img {
    width: 13px;
    height: 13px;
  }
}

.modalContent {
  width: 100%;
}

.labeledInput {
  width: calc(50% - 8px);

  &[data-is-visible='false'] {
    display: none;
  }

  @media screen and (width <=750px) {
    width: 100%;
  }
}

.divider {
  margin-top: 22px;
  background: var(--E5E5E5, #e5e5e5);
  width: 100%;
  height: 1px;
}

.codeHashSelect {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 1rem;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sectionTitle {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 16px 0;
}

.modalFooter {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 8px 0;
}

.report {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  color: #666;

  a {
    line-height: 1rem;
    color: #333;
    appearance: none;
    border: none;
    border-radius: 4px;
    margin-left: 0.25rem;

    // FIXME: use css variable
    background: #f5f5f5;
    padding: 0.5rem;
    cursor: pointer;
  }
}

.vericodeEmail {
  margin: 0.75rem 0;
}

.vericode {
  position: relative;
  width: 50%;
  height: 2.625rem;
  margin-bottom: 1rem;

  input {
    width: 100%;
    height: 100%;
    border: 1px solid #e5e5e5;
    padding: 0.75rem;
  }

  button {
    top: 0;
    right: 0;
    height: 100%;
    position: absolute;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    min-width: 82px;
    border: none;
    color: var(--primary-color);
    font-size: 0.875rem;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  @media (width <= $mobileBreakPoint) {
    width: 100%;
  }
}

.submitBtn {
  width: 8.75rem;
  white-space: nowrap;
}
