@import '../../../styles/variables.module';

.contentWrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 80vw;
  max-width: 280px;
  padding: 24px;

  @media screen and (width <= 750px) {
    padding: 16px;
    width: calc(100vw - 32px);
  }

  p {
    margin: 0;
  }
}

.modalTitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.modalContent {
  width: 100%;
  margin-top: 32px;
}

.languageSelect {
  color: #333;
}

.closeBtn {
  border: none;
  background: transparent;
  cursor: pointer;

  img {
    width: 13px;
    height: 13px;
  }
}

.loading {
  text-align: center;
}
