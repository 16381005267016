@import '../../../styles/variables.module';

.pagination {
  margin-top: 3px;
  margin-bottom: 40px;

  @media (width <= $mobileBreakPoint) {
    padding: 16px;
  }
}

.title {
  margin-top: 40px;
  font-size: 24px;
  line-height: 28px;
  color: #333;

  @media (width <= $mobileBreakPoint) {
    margin-top: 20px;
    font-size: 20px;
    line-height: 23px;
  }
}
