.container {
  background-color: var(--primary-color);
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  padding: 14px 40px;
  color: white;
  border: none;
  border-radius: 4px;
}

.container:hover {
  background-color: var(--primary-color);
}

.isDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loading .dot {
  display: inline-block;
  animation: loading 1.5s infinite;
}

.loading {
  margin-left: 4px;
}

@keyframes loading {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }
}

.loading .dot:nth-child(1) {
  animation-delay: 0s;
}

.loading .dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loading .dot:nth-child(3) {
  animation-delay: 1s;
}
