.container {
  font-size: 14px;
}

.label {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
  margin-bottom: 12px;
}

.labelRightAddon {
  display: flex;
  align-self: center;
  margin-left: 4px;
}

.input {
  display: none;
}

.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 112px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  background: white;
  user-select: none;
}

.uploadIconWrapper {
  cursor: pointer;
}

.uploadIcon {
  width: 24px;
  height: 24px;
}

.deleteIcon {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
  background: rgb(0 0 0 / 50%);

  img {
    width: 16px;
    height: 16px;
  }
}

.uploadedIcon {
  width: 112px;
  height: 112px;
  object-fit: scale-down;
}

.placeholder {
  color: #999;
  font-size: 12px;
}
