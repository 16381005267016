.searchResultsPanelWrapper {
  position: absolute;
  z-index: 2;
  top: calc(100% + 8px);
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-height: 292px;
  overflow-y: auto;
  background: white;
  color: black;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgb(16 16 16 / 5%);
}

.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlight {
  color: var(--primary-color);
}

.empty {
  padding: 28px 0;
  text-align: center;
  font-size: 16px;
  color: #333;
}

.searchResult {
  display: block;
  width: 100%;
  padding: 4px 0;
  cursor: pointer;
  border-bottom: solid 1px #e5e5e5;

  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &:focus-within {
    .content,
    .boxContent {
      background: #f5f5f5;
    }
  }
}

.boxContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  padding: 4px;
  border-radius: 4px;
}

.content {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  padding: 4px;
  border-radius: 4px;
}

.tokenSymbol {
  font-size: 16px;
  color: #333;
}

.secondaryText {
  font-size: 14px;
  color: #666;
}

.subTitle {
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
}

.categoryTitle {
  color: #666;
  font-size: 0.65rem;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding: 12px 12px 6px;
  background-color: #f5f5f5;
}

.categoryList {
  padding: 6px 8px;
}

.icon {
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 6px;
  flex-shrink: 0;
  object-fit: cover;
}

.searchCategoryFilter {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 12px 0;
  gap: 4px;
}

.searchCategoryTag {
  border: 1px solid #e5e5e5;
  border-radius: 24px;
  padding: 4px 12px;
  cursor: pointer;
  transition: all 0.3s;

  &.active {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
}

.rgbPlus {
  color: #333;
  display: flex;
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #ffd176 0.23%, #ffdb81 6.7%, #84ffcb 99.82%);
  vertical-align: middle;
}
