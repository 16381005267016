.select {
  position: relative;
  font-size: 14px;
  padding: 11px 8px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  background: white;
  cursor: pointer;
  user-select: none;
}

.value {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.options {
  z-index: 1;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  top: 45px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  background: white;
  width: 100%;
}

.option {
  padding: 11px 8px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #f5f5f5;
  }
}

.arrow {
  width: 10px;
  transition: 0.3s;

  &[data-is-flipped='true'] {
    transform: rotateX(180deg);
  }
}

.selected {
  background: #f5f5f5;
}
