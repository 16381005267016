.autoExpand {
  display: flex;
  flex: 1;
  min-width: 0;

  .content {
    display: flex;
    flex-shrink: 0;
  }

  .expandable {
    display: flex;
    justify-content: right;
    flex: 1;
    min-width: 0;
  }
}

.stickyContainer {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;

  &.expanded {
    height: 100vh;
  }
}
