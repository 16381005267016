.container {
  appearance: none;
  border: none;
  outline: none;
  background: none;
  display: inline-flex;
  vertical-align: text-top;
  margin-left: 8px;
  cursor: pointer;

  &[data-order='desc'] {
    svg > path:first-child {
      fill: var(--primary-color);
    }
  }

  &[data-order='asc'] {
    svg > path:last-child {
      fill: var(--primary-color);
    }
  }
}
