.pageSizeSelector {
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  align-content: stretch;
  height: 100%;
  margin: 0 2rem;
  font-size: 0.875rem;
  line-height: 1em;
  color: #333;

  .pageSize {
    text-align: left;
    width: 3.5rem;
    margin-left: 0.5rem;
    border-radius: 4px;
    padding: 8px;
    height: 2rem;
    background: var(--input-bg-color);
    border: none;

    &:focus {
      & + div[role='menu'] {
        display: flex;
      }
    }
  }

  div[role='menu'] {
    position: absolute;
    width: 3.5rem;
    top: 100%;
    right: 0;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    padding: 2px 0;
    background: #fff;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 #eee;
  }

  div[role='menuitem'] {
    width: 100%;
    padding: 0.375rem 0.5rem;
    cursor: pointer;
    text-align: left;

    &:hover {
      background: var(--input-bg-color);
    }

    &[data-is-selected='true'] {
      color: var(--primary-color);
    }
  }

  &:hover {
    div[role='menu'] {
      display: flex;
    }
  }

  // FIXME: the layout will be optimized later with a new design
  @media (750px <=width) and (width <= 900px) {
    display: none;
  }

  @media (width <= 600px) {
    display: none;
  }
}
