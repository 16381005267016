.container {
  min-width: 300px;
  background: #fff;
  position: absolute;
  user-select: none;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 #eee;
  z-index: 999;
  pointer-events: none;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    gap: 1rem;
    padding: 0.5rem 1rem;
    z-index: 1;
  }

  .select {
    position: relative;
    background: #fff;
    width: 150px;
    pointer-events: auto;

    label {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      align-items: center;
      padding: 0.5rem calc(1.5rem - 2px);
      cursor: pointer;
      white-space: nowrap;

      &::after {
        content: '';
        display: block;
        border: 4px solid transparent;
        border-left: 4px solid currentcolor;
        border-top: 4px solid currentcolor;
        transform: translateY(-2px) rotate(-135deg);
      }
    }

    &:hover {
      label {
        &::after {
          transform: translateY(2px) rotate(45deg);
        }
      }

      ul {
        display: block;
      }
    }

    ul {
      display: none;
      background: #fff;
      width: 100%;
      white-space: nowrap;
      position: absolute;
      list-style: none;
      box-shadow: 0 2px 10px 0 #eee;
      padding: 0.5rem;
      border-radius: 4px;
    }

    li {
      cursor: pointer;
      border-radius: 4px;
      padding: 0.5rem 1rem;

      &:hover {
        background: #f9f9f9;
      }

      &[data-is-active='true'] {
        font-weight: 900;
      }
    }
  }

  .body {
    padding: 1rem;
    position: relative;

    pre {
      background: #f9f9f9;
      border-radius: 4px;
      letter-spacing: 0.5px;
      margin: 0;
      padding: 1.5rem 0.5rem 0.5rem;
      word-break: break-all;
      white-space: break-spaces;
      font-family: Roboto, Lato, sans-serif, Montserrat, 'PingFang SC', -apple-system;
      max-height: 300px;
      pointer-events: auto;
    }

    .count {
      text-align: right;
      font-size: 0.8em;
      padding-top: 4px;
      color: #999;
    }

    .copy {
      z-index: 0;
      position: absolute;
      border: none;
      background: none;
      appearance: none;
      right: 1.5rem;
      top: 1.5rem;
      cursor: pointer;
      color: #999;
      padding-left: 1rem;
      pointer-events: auto;

      svg {
        width: 1rem;
        height: 1rem;
        background: #f9f9f9;
      }

      ul {
        display: none;
        position: absolute;
        right: 0;
        padding: 0.5rem;
        top: 1rem;
        color: #000;
        background: #fff;
        width: min-content;
        white-space: nowrap;
        list-style: none;
        box-shadow: 0 2px 10px 0 #eee;
        border-radius: 4px;
        text-transform: capitalize;

        li {
          text-align: left;
          padding: 0 1rem;
        }

        li:hover {
          background: #f9f9f9;
          color: var(--primary-color);
          border-radius: 4px;
        }
      }

      &:hover {
        ul {
          display: block;
        }
      }
    }
  }
}
