@import '../../styles/variables.module';

.searchInputPanel {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 0 8px;
  background: white;
  color: #333;
  border: 0 solid white;
  border-radius: 4px;

  &:focus {
    color: #333;
    outline: none;
  }

  &::placeholder {
    color: #888;
  }

  @media (max-width: $mobileBreakPoint) {
    font-size: 12px;
    width: 100%;
    padding-left: 6px;
    padding-right: 16px;
  }
}

.preIcon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.spinner {
  animation: rotate 2s linear infinite;
}

.clear {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.byNameOrId:not(
    [_='This `:not` selector is used to increase the specificity of the selector and serves no other purpose.']
  ) {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin-left: 16px;
  padding: 0 8px;
  color: #666;
  background: #f0f0f0;
  border-radius: 2px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
